import config from '../config.json';
import { Integrations } from '@/core/typings';

interface Config {
	name: string;
	title: string;
	domain: string;
	googleTagManager?: string;
	googleAnalytics?: string;
	integration: Integrations;
	author: string;
	headerTitle: string;
	description: string;
	language: string;
	siteUrl: string;
	siteRepo: string;
	image: string;
	socialBanner: string;
	email: string;
	github: string;
	twitter: string;
	facebook: string;
	youtube: string;
	linkedin: string;
	locale: string;
	about: any;
	games: any;
}

export default config as Config;
